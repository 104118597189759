export default {
  name: 'Home',
  components: {
    VeiculosDestaque: () => import('src/components/VeiculosDestaque/VeiculosDestaque.vue'),
    FilterBox: () => import('src/components/FilterBox/FilterBox.vue'),
    VejaTodos: () => import('src/components/VejaTodos/VejaTodos.vue'),
    ConfiraTodos: () => import('src/components/ConfiraTodos/ConfiraTodos.vue'),
    ComoFunciona: () => import('src/components/ComoFunciona/ComoFunciona.vue'),
    Parceria: () => import('src/components/Parceria/Parceria.vue'),
    Beneficios: () => import('src/components/Estaticos/Beneficios/Beneficios.vue'),
    MaisBuscados: () => import('src/components/Estaticos/MaisBuscados/MaisBuscados.vue'),
    VendaAgora: () => import('src/components/VendaAgora/VendaAgora.vue'),
    Vantagens: () => import('src/components/Vantagens/Vantagens.vue'),
    CategoriaCarrossel: () => import('src/components/CategoriaCarrossel/CategoriaCarrossel.vue')
  },
  meta () {
    return {
      title: 'NetCarros, venda e compra carros usados e seminovos',
      description: {
        name: 'description',
        content: 'Quer comprar ou vender seu veículo sem sair de casa? A NetCarros é o lugar certo. Temos os melhores carros usados e seminovos do mercado. Acesse agora!'
      },
      property: { 'name': 'og:image', content: 'https://www.netcarros.com.br/seo/netcarros-home.jpg' },
      link: { material: { rel: 'canonical', href: 'https://www.netcarros.com.br' + this.$route.fullPath } },
      meta: [
        { 'property': 'og:title', 'content': 'NetCarros, venda e compra carros usados e seminovos' },
        { 'name': 'description', 'content': 'Quer comprar ou vender seu veículo sem sair de casa? A NetCarros é o lugar certo. Temos os melhores carros usados e seminovos do mercado. Acesse agora!' },
        { 'property': 'revisit-after', 'content': '5 days' },
        { 'property': 'og:image', 'content': 'https://www.netcarros.com.br/seo/netcarros-home.jpg' },
        { 'property': 'og:image:secure_url', 'content': 'https://www.netcarros.com.br/seo/netcarros-home.jpg' },
        { 'property': 'og:url', 'content': 'https://www.netcarros.com.br/' },
        { 'property': 'og:site_name', 'content': 'NetCarros' },
        { 'property': 'fb:admins', 'content': '', 'vmid': 'fb:admins' },
        { 'property': 'og:locale', 'content': 'pt_BR' }
      ]
    }
  },
  data () {
    return {
      selectedBrand: 0,
      popup: {
        show: false
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    if (process.env.MODE !== 'ssr' || process.env.CLIENT) {
      document.querySelector('.botoes a').href = 'https://api.whatsapp.com/send?phone=551151980247&text=Olá%20gostaria%20de%20saber%20com%20quais%20carros%20vocês%20trabalham?'
    }
    next()
  }

}
